import React, { useEffect, useState, useContext } from 'react';
import './Question.css';
import { SizeContext } from '../context';
import { Droplet, Item, Lottie, QuestionI, TextI } from "./Interfaces";
import { updateItem, updateLottie, TrueButton, FalseButton, Keimeno2Image, TextBorder, TextBorderImage, ScoreBoxImage, QuestionBox, ScoreLevelBox, ScoreScoreBox, LevelUp, PameXana } from "./Constants";
import { CorrectAnswer, WrongAnswer } from "./Constants";
import { ButtonItem } from './Animations/Image/ButtonItem'
import ReactAudioPlayer from 'react-audio-player';
import { AnimateLottie } from './Animations/AnimateLottie';
import { AnimateItem } from './Animations/Image/AnimateItem'
import { CustomAudio } from './CustomAudio';
import { Odometer1, Odometer2, Odometer3, Odometer4 } from './Constants';

interface QuestionProps {
    gameOver: boolean;
    showQuestion: boolean;
    selectedDroplet: Droplet;
    correctAnswer: () => void;
    wrongAnswer: () => void;
    onLevelChange: (number) => void;
    onRestart: () => void;
}

export const Question: React.FC<QuestionProps> = ({ gameOver, showQuestion, selectedDroplet, correctAnswer, wrongAnswer, onLevelChange, onRestart }: QuestionProps) => {
    const [scale, setScale] = useState(1.0);
    const [trueButton, setTrueButton] = useState<Item>(TrueButton);
    const [falseButton, setFalseButton] = useState<Item>(FalseButton);

    const [keimeno2Image, setKeimeno2Image] = useState<Item>(Keimeno2Image);

    const [textBorder, setTextBorder] = useState<Lottie>(TextBorder);
    const [textBorderImage, setTextBorderImage] = useState<Item>(TextBorderImage);

    const [scoreBoxImage, setScoreBoxImage] = useState<Item>(ScoreBoxImage);
    const [questionBox, setQuestionBox] = useState<Item>(QuestionBox);

    const [showText, setShowText] = useState(true);
    const [showTextBorderLottie, setShowTextBorderLottie] = useState(true);

    const [showQuestionBox, setShowQuestionBox] = useState(false);

    const [showShowQuestionLocal, setShowQuestionLocal] = useState(false);

    const [score, setScore] = useState(0);
    const [scoreString, setScoreString] = useState("0000");
    const [level, setLevel] = useState("LEVEL 1");

    const [scoreLevelBox, setScoreLevelBox] = useState<Item>(ScoreLevelBox);
    const [scoreScoreBox, setScoreScoreBox] = useState<Item>(ScoreScoreBox);

    const [questions, setQuestions] = useState<any>(undefined);
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionI | undefined>(undefined);
    const [usedQuestion, setUsedQuestion] = useState<number[]>([]);

    const [playCorrectResponse, setPlayCorrectResponse] = useState(false);
    const [playInCorrectResponse, setPlayInCorrectResponse] = useState(false);

    const [playLevelUpSound, setPlayLevelUpSound] = useState(false);

    const [odometer1, setOdometer1] = useState<Lottie>(Odometer1);
    const [odometer2, setOdometer2] = useState<Lottie>(Odometer2);
    const [odometer3, setOdometer3] = useState<Lottie>(Odometer3);
    const [odometer4, setOdometer4] = useState<Lottie>(Odometer4);

    const [showOdometer1, setShowOdometer1] = useState(false);
    const [showOdometer2, setShowOdometer2] = useState(false);
    const [showOdometer3, setShowOdometer3] = useState(false);
    const [showOdometer4, setShowOdometer4] = useState(false);

    const [levelUp, setLevelUp] = useState<Lottie>(LevelUp);

    const [pameXana, setPameXana] = useState<Item>(PameXana);

    const [showPamexana, setShowPamaxana] = useState(false);

    const context = useContext(SizeContext);

    useEffect(() => {
        setTrueButton(updateItem(TrueButton, scale));
        setFalseButton(updateItem(FalseButton, scale));
        setKeimeno2Image(updateItem(Keimeno2Image, scale));

        setTextBorder(updateLottie(TextBorder, scale));
        setTextBorderImage(updateItem(TextBorderImage, scale));

        setScoreBoxImage(updateItem(ScoreBoxImage, scale));
        setQuestionBox(updateItem(QuestionBox, scale));

        setScoreLevelBox(updateItem(ScoreLevelBox, scale));
        setScoreScoreBox(updateItem(ScoreScoreBox, scale));

        setOdometer1(updateLottie(Odometer1, scale));
        setOdometer2(updateLottie(Odometer2, scale));
        setOdometer3(updateLottie(Odometer3, scale));
        setOdometer4(updateLottie(Odometer4, scale));

        setLevelUp(updateLottie(LevelUp, scale));

        setPameXana(updateItem(PameXana, scale));
    }, [scale]);

    useEffect(() => {
        onScreenresize();
        window.addEventListener("resize", handleScreenResize);
        window.addEventListener("orientationchange", handleScreenResize);
        window.screen.orientation.addEventListener("change", handleScreenResize);

        fetch('questions.json').then(
            function (res) {
                return res.json()
            }).then(function (data) {
                setQuestions(data);
            }).catch(
                function (err) {
                    console.log(err, ' error')
                }
            )

        return () => {
            window.removeEventListener('resize', handleScreenResize);
            window.removeEventListener('orientationchange', handleScreenResize);
            window.screen.orientation.removeEventListener('change', handleScreenResize);
        };

    }, [])

    useEffect(() => {
        setShowQuestionLocal(showQuestion);
        if (showQuestion) {
            setShowTextBorderLottie(true);

            pickQuestion();
            setTimeout(() => {
                setShowQuestionBox(true);
            }, 500);
        } else {
            setShowQuestionBox(false);
            setSelectedQuestion(undefined);
        }
    }, [showQuestion]);

    useEffect(() => {
        if (gameOver) {
            setShowText(false);

            if (score <= 500)
                setShowOdometer1(true)
            else if (score <= 1500)
                setShowOdometer2(true)
            else if (score <= 2500)
                setShowOdometer3(true)
            else
                setShowOdometer4(true)
        }
    }, [gameOver]);

    const pickQuestion = () => {
        var qindex = Math.floor(Math.random() * questions.length);

        if (usedQuestion.includes(qindex)) {
            pickQuestion()
            return
        }
        setUsedQuestion(oldArray => [...oldArray, qindex]);
        setSelectedQuestion(questions[qindex]);
    }

    const handleScreenResize = () => {
        onScreenresize();
    }

    const onScreenresize = () => {
        resizeScreen();
        var referenceWidth = 1920.0;
        var referenceHeight = 1080.0;

        var windowWidth = context.width;
        var windowHeight = context.height;

        var _scaleWidth = windowWidth / referenceWidth;
        var _scaleHeight = windowHeight / referenceHeight;

        var _scale = Math.min(_scaleHeight, _scaleWidth);

        setScale(_scale);
    }

    const resizeScreen = () => {
        var ratio = 1920.0 / 1080.0;
        var windowHeight = Math.min(window.screen.height, window.innerHeight);
        var windowWidth = Math.min(window.screen.width, window.innerWidth);

        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (webkit && !iOSSafari) {
           windowHeight = context.innerHeight / window.devicePixelRatio;
            windowWidth = context.innerWidth / window.devicePixelRatio;
        }

        var webWidth = windowWidth;
        var webHeight = windowWidth / ratio;

        if (webHeight > windowHeight) {
            webHeight = windowHeight - 2;
            webWidth = webHeight * ratio;
        }

        context.width = Math.min(webWidth, 1920);
        context.height = Math.min(webHeight, 1080);
    }

    const checkAnswer = (user: boolean) => {
        if (user == selectedQuestion.answer) {
            userGameCorrectAnswer();
            setPlayCorrectResponse(true);
            correctAnswer();
        } else {
            setPlayInCorrectResponse(true);
            wrongAnswer();
        }

    }
    const userGameCorrectAnswer = () => {
        setScore(value => {
            var earnedPoints = 100;
            if (selectedDroplet == Droplet.Cloud)
                earnedPoints = 200;
            else if (selectedDroplet == Droplet.Well)
                earnedPoints = 150;

            var newValue = value + earnedPoints;

            if (newValue > 700 && level == "LEVEL 1") {
                newValue += 500
                setLevel("LEVEL 2");
                onLevelChange(2);
                setPlayLevelUpSound(true);
            } else if (newValue > 2000 && level == "LEVEL 2") {
                newValue += 500
                setLevel("LEVEL 3");
                onLevelChange(3);
                setPlayLevelUpSound(true);
            } else if (newValue > 3000 && level == "LEVEL 3") {
                newValue += 500
                setLevel("LEVEL 4");
                onLevelChange(4);
                setPlayLevelUpSound(true);
            }

            var s = "000" + newValue;
            setScoreString(s.substr(s.length - 4));

            setTimeout(() => {
                setPlayLevelUpSound(false);
            }, 1000);

            return newValue;
        });
    }

    const onOdometerEnd = () => {
        setShowPamaxana(true);
    }

    const restartGame = () => {
        setShowPamaxana(false);
        setLevel("LEVEL 1");
        onLevelChange(1);
        setScore(value => {
            var newValue = 0;
            var s = "000" + newValue;
            setScoreString(s.substr(s.length - 4));

            return newValue;
        });
        setShowOdometer1(false);
        setShowOdometer2(false);
        setShowOdometer3(false);
        setShowOdometer4(false);
        setShowText(true);
        setShowQuestionLocal(false);
        onRestart()
    }

    return (
        <>
            <AnimateItem
                item={scoreBoxImage}
                loop={false}
                stop={false}
                hideButton={false}
                onEnd={() => { }}
            />

            <div className="container" style={{
                width: scoreLevelBox.size.width, height: scoreLevelBox.size.height, top: scoreLevelBox.position.top, left: scoreLevelBox.position.left
            }}>
                <span className="level">{level}
                </span>
            </div>

            <div className="container" style={{
                width: scoreScoreBox.size.width, height: scoreScoreBox.size.height, top: scoreScoreBox.position.top, left: scoreScoreBox.position.left
            }}>
                <span className="score">{scoreString}
                </span>
            </div>

            <div> {/*className='Question'>*/}
                {showShowQuestionLocal && !gameOver &&
                    <>

                        <div style={{ opacity: showTextBorderLottie ? 0 : 1.0 }}>
                            <AnimateItem
                                item={textBorderImage}
                                loop={false}
                                stop={false}
                                hideButton={false}
                                onEnd={() => { }}
                            />
                        </div>
                        {showTextBorderLottie &&
                            <AnimateLottie
                                item={textBorder}
                                removeItemAtEnd={true}
                                loop={false}
                                stop={false}
                                hideButton={false}
                                onEnd={() => { setShowTextBorderLottie(false) }}
                                onTap={() => { }}
                            />
                        }

                        {showQuestionBox && selectedQuestion &&
                            <div className="container" style={{
                                display: 'table',
                                width: questionBox.size.width, height: questionBox.size.height,
                                top: questionBox.position.top, left: questionBox.position.left
                            }}>
                                <div className="pop-outin">
                                    <span dangerouslySetInnerHTML={{ __html: selectedQuestion.text }}></span>

                                </div>
                            </div>
                        }

                        <ButtonItem
                            item={trueButton}
                            shouldHearClickSound={false}
                            onTap={() => {
                                checkAnswer(true);
                            }}
                        />
                        <ButtonItem
                            item={falseButton}
                            shouldHearClickSound={false}
                            onTap={() => {
                                checkAnswer(false);
                            }}
                        />
                    </>
                }

                {showText && !showShowQuestionLocal &&
                    <AnimateItem
                        item={keimeno2Image}
                        loop={false}
                        stop={false}
                        hideButton={true}
                        onEnd={() => { }}
                    />
                }
            </div>

            {showOdometer1 &&
                <AnimateLottie
                    item={odometer1}
                    removeItemAtEnd={false}
                    loop={false}
                    stop={false}
                    hideButton={false}
                    onEnd={onOdometerEnd}
                    onTap={() => { }}
                />
            }

            {showOdometer2 &&
                <AnimateLottie
                    item={odometer2}
                    removeItemAtEnd={false}
                    loop={false}
                    stop={false}
                    hideButton={false}
                    onEnd={onOdometerEnd}
                    onTap={() => { }}
                />
            }

            {showOdometer3 &&
                <AnimateLottie
                    item={odometer3}
                    removeItemAtEnd={false}
                    loop={false}
                    stop={false}
                    hideButton={false}
                    onEnd={onOdometerEnd}
                    onTap={() => { }}
                />
            }

            {showOdometer4 &&
                <AnimateLottie
                    item={odometer4}
                    removeItemAtEnd={false}
                    loop={false}
                    stop={false}
                    hideButton={false}
                    onEnd={onOdometerEnd}
                    onTap={() => { }}
                />
            }

            {showPamexana &&
                <ButtonItem
                    item={pameXana}
                    shouldHearClickSound={true}
                    onTap={restartGame}
                />
            }

            {playLevelUpSound &&
                <AnimateLottie
                    item={levelUp}
                    removeItemAtEnd={true}
                    loop={false}
                    stop={false}
                    hideButton={false}
                    onEnd={() => { }}
                    onTap={() => { }}
                />
            }

            <CustomAudio
                item={LevelUp}
                loop={false}
                start={playLevelUpSound} />

            <CustomAudio
                item={CorrectAnswer}
                loop={false}
                start={playCorrectResponse}
                onAudioEnd={() => { setPlayCorrectResponse(false) }}
            />

            <CustomAudio
                item={WrongAnswer}
                loop={false}
                start={playInCorrectResponse}
                onAudioEnd={() => { setPlayInCorrectResponse(false) }}
            />

            <CustomAudio
                item={Odometer1}
                loop={false}
                start={showOdometer1 || showOdometer2 || showOdometer3 || showOdometer4} />
        </>
    )
}