import React, { useEffect, useState, useContext, useReducer } from 'react';
import '../Game.css';
import { SizeContext } from '../../context';
import { Item } from "../Interfaces";
import { updateItem, StaticLeftScreen } from "../Constants"
import { AnimateItem } from '../Animations/Image/AnimateItem'
import ReactPlayer from 'react-player'

interface EntryLeftProps {
}

export const EntryLeft: React.FC<EntryLeftProps> = ({ }: EntryLeftProps) => {
    const [scale, setScale] = useState(1.0);
    const [staticLeftScreen, setStaticLeftScreen] = useState<Item>(StaticLeftScreen);

    const context = useContext(SizeContext);

    useEffect(() => {
        setStaticLeftScreen(updateItem(StaticLeftScreen, scale));
    }, [scale]);

    useEffect(() => {
        onScreenresize();
        window.addEventListener("resize", handleScreenResize);
        window.addEventListener("orientationchange", handleScreenResize);
        window.screen.orientation.addEventListener("change", handleScreenResize);

        return () => {
            window.removeEventListener('resize', handleScreenResize);
            window.removeEventListener('orientationchange', handleScreenResize);
            window.screen.orientation.removeEventListener('change', handleScreenResize);
        };

    }, [])

    const handleScreenResize = () => {
        onScreenresize();
    }

    const onScreenresize = () => {
        resizeScreen();
        var referenceWidth = 1920.0;
        var referenceHeight = 1080.0;

        var windowWidth = context.width;
        var windowHeight = context.height;

        var _scaleWidth = windowWidth / referenceWidth;
        var _scaleHeight = windowHeight / referenceHeight;

        var _scale = Math.min(_scaleHeight, _scaleWidth);

        setScale(_scale);
    }

    const resizeScreen = () => {
        var ratio = 1920.0 / 1080.0;
        var windowHeight = Math.min(window.screen.height, window.innerHeight);
        var windowWidth = Math.min(window.screen.width, window.innerWidth);

        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (webkit && !iOSSafari) {
            windowHeight = window.innerHeight / window.devicePixelRatio;
            windowWidth = window.innerWidth / window.devicePixelRatio;
        }

        var webWidth = windowWidth;
        var webHeight = windowWidth / ratio;

        if (webHeight > windowHeight) {
            webHeight = windowHeight - 2;
            webWidth = webHeight * ratio;
        }

        context.width = Math.min(webWidth, 1920);
        context.height = Math.min(webHeight, 1080);
    }

    return (
        <div className='Game'>
            <AnimateItem
                item={staticLeftScreen}
                loop={false}
                stop={false}
                hideButton={true}
                onEnd={() => { }}
            />
        </div>
    )
}