import React, { useEffect, useState, useContext } from 'react';
import './Game.css';
import { SizeContext } from '../context';
import { Droplet, Item, Lottie } from "./Interfaces";
import { updateItem, updateLottie, Sun, Cloud1, Cloud2, Cloud3, Well, WaterBar, Pipe, PipeStatic, WellWater } from "./Constants";
import { Cloud3Buildout, DropletPipe, DropletCloud, DropletWellWater, CountDown } from "./Constants";
import { AnimateItem } from './Animations/Image/AnimateItem'
import { AnimateLottie } from './Animations/AnimateLottie';
import ReactPlayer from 'react-player'
import { CustomAudio } from './CustomAudio';

interface GameProps {
    levelCount: number,
    restart: boolean,
    onDropletTapped: (Droplet) => void;
    showQuestion: boolean;
    correctIsGiven: boolean;
    onGameOver: () => void;
}

export const Game: React.FC<GameProps> = ({ levelCount, restart, onDropletTapped, showQuestion, correctIsGiven, onGameOver }: GameProps) => {
    const [scale, setScale] = useState(1.0);

    const [gameIsStopped, setStopGame] = useState(false);
    const [tappedOnCloud, setTappedOnCloud] = useState(false);

    const [sun, setSun] = useState<Lottie>(Sun);

    const [showCloud, setShowCloud] = useState<Lottie | undefined>(undefined);
    const [showThunderCloud, setShowThunderCloud] = useState(false);

    const [cloud1, setCloud1] = useState<Lottie>(Cloud1);
    const [cloud2, setCloud2] = useState<Lottie>(Cloud2);
    const [cloud3, setCloud3] = useState<Lottie>(Cloud3);
    const [dropletCloud, setDropletCloud] = useState<Lottie>(DropletCloud);
    const [cloud3Buildout, setCloud3Buildout] = useState<Lottie>(Cloud3Buildout);

    const [pipe, setPipe] = useState<Lottie>(Pipe);
    const [dropletPipe, setDropletPipe] = useState<Lottie>(DropletPipe);
    const [pipeStatic, setPipeStatic] = useState<Item>(PipeStatic);

    const [shouldAnimatePipe, setShouldAnimatePipe] = useState(false);
    const [tappedOnPipe, setTappedOnPipe] = useState(false);

    const [well, setWell] = useState<Item>(Well);
    const [waterBar, setWaterBar] = useState<Item[]>([WaterBar]);
    const [wellWater, setWellWater] = useState<Lottie>(WellWater);
    const [dropletWellWater, setDropletWellWater] = useState<Lottie>(DropletWellWater);

    const [shouldAnimateWellWater, setShouldAnimateWellWater] = useState(false);
    const [tappedOnWellWater, setTappedOnWellWater] = useState(false);

    const [waterCount, setWaterCount] = useState<number>(19);

    const [randomCloudNumber, setRandomCloudNumber] = useState<number>(-1);
    const [randomDropletNumber, setRandomDropletNumber] = useState<number>(-1);

    const [isRemovingWater, setIsRemovingWater] = useState(false);
    const [isAddingWater, setIsAddingWater] = useState(false);
    const [startTogglingWater, setStartTogglingWater] = useState(false);

    const [showDropletCloud, setShowDropletCloud] = useState(false);
    const [showDropletPipe, setShowDropletPipe] = useState(false);
    const [showDropletWell, setShowDropletWell] = useState(false);

    const [playCountDown, setPlayCountDown] = useState(true);
    const [countDown, setCountDown] = useState<Item>(CountDown);

    const [gameIsStarted, setGameIsStarted] = useState(false);

    const context = useContext(SizeContext);

    const waterBarTimer = 7000;

    useEffect(() => {
        if (restart) {
            setTappedOnPipe(false);
            setTappedOnWellWater(false);
            setTappedOnCloud(false);

            setGameIsStarted(true);
            setStopGame(false)
            setWaterCount(19);

            setTimeout(() => {
                startTimerForDropletAnimation();
            }, 4000);
            setTimeout(() => {
                startTimerForWaterBar();
            }, 500);
        }
    }, [restart])

    useEffect(() => {
        if (gameIsStopped)
            return;

        if (waterCount === 0) {
            createWaterBar();
            setStopGame(true);
            setGameIsStarted(false);
            setRandomDropletNumber(-1);
            setShouldAnimatePipe(false);
            setShouldAnimateWellWater(false);
            setShowThunderCloud(false);
            setShowDropletCloud(false);
            setShowDropletPipe(false);
            setShowDropletWell(false);
            setShowCloud(undefined);
            onGameOver();
            //alert("Game is over");
        } else if (waterCount > 0) {
            createWaterBar();
        }
    }, [waterCount, scale]);

    function createWaterBar() {
        var bar: Item[] = [];
        let start = 19 - waterCount;
        for (var i = start; i < 19; i++) {
            //var wb = structuredClone(WaterBar)
            var wb = { ...WaterBar };
            wb.position.top = 478;
            wb.position.left = 416;
            wb.position.top += i * 22;
            bar.push(updateItem(wb, scale));
        }
        setWaterBar(bar);
    }

    useEffect(() => {
        setSun(updateLottie(Sun, scale));

        setCloud1(updateLottie(Cloud1, scale));
        setCloud2(updateLottie(Cloud2, scale));
        setCloud3(updateLottie(Cloud3, scale));
        setDropletCloud(updateLottie(DropletCloud, scale));
        setCloud3Buildout(updateLottie(Cloud3Buildout, scale));

        setWell(updateItem(Well, scale));

        setPipe(updateLottie(Pipe, scale));
        setPipeStatic(updateItem(PipeStatic, scale));
        setWellWater(updateLottie(WellWater, scale));

        setDropletPipe(updateLottie(DropletPipe, scale));

        setDropletWellWater(updateLottie(DropletWellWater, scale));

        setCountDown(updateItem(CountDown, scale));

    }, [scale]);

    useEffect(() => {
        // console.log("randomCloudNumber " + randomCloudNumber);
        if (showThunderCloud) {
            startTimerForCloudAnimation();
            return;
        }
        if (randomCloudNumber === 1) {
            setShowThunderCloud(false);
            setShowCloud(updateLottie(Cloud1, scale));
        }
        else if (randomCloudNumber === 2) {
            setShowThunderCloud(false);
            setShowCloud(updateLottie(Cloud2, scale));
        }
       /* else if (randomCloudNumber === 3) {
            if (!shouldAnimatePipe) {
                setShowCloud(updateLottie(Cloud3, scale));
            } else {
                startTimerForCloudAnimation();
            }
        }*/ else {
            setShowCloud(undefined);
        }
    }, [randomCloudNumber]);

    useEffect(() => {
        // console.log("randomDropletNumber " + randomDropletNumber);
        /* if (showThunderCloud || shouldAnimatePipe || shouldAnimateWellWater) {
             console.log("Droplet is already presented!!!");
             return;
         }*/

        if (gameIsStopped)
            return;

        if (randomDropletNumber < 4 && randomDropletNumber > 0) {
            if (showCloud === undefined) {
                setShowThunderCloud(true);
                setShowCloud(updateLottie(Cloud3, scale));
            } else {
                let rnd = randomIntFromInterval(1, 2);
                if (rnd == 1)
                    setShouldAnimatePipe(true);
                else
                    setShouldAnimateWellWater(true);
            }
        }
        else if (randomDropletNumber < 13 && randomDropletNumber > 0) {
            setShouldAnimatePipe(true);
        }
        else if (randomDropletNumber >= 13) {
            setShouldAnimateWellWater(true);
        }
    }, [randomDropletNumber]);

    useEffect(() => {
        if (gameIsStopped)
            return;

        if (!startTogglingWater)
            return;

        if (!isRemovingWater) {
            setWaterCount(value => {
                return value - 1
            });
        }
    }, [startTogglingWater, isRemovingWater]);

    useEffect(() => {
        if (isRemovingWater && !gameIsStopped) {
            startTimerForWaterBar();
        }
    }, [isRemovingWater]);

    useEffect(() => {
        if (gameIsStopped)
            return;

        if (!startTogglingWater)
            return;

        if (isAddingWater && waterCount < 19) {
            setWaterCount(value => {
                return value + 1
            });
        }
    }, [startTogglingWater, isAddingWater]);

    useEffect(() => {
        if (correctIsGiven) {
            setStartTogglingWater(true);
            setIsAddingWater(true);

            setTimeout(() => {
                setIsAddingWater(false);
            }, 300);
        }
    }, [correctIsGiven])

    useEffect(() => {
        onScreenresize();
        window.addEventListener("resize", handleScreenResize);
        window.addEventListener("orientationchange", handleScreenResize);
        window.screen.orientation.addEventListener("change", handleScreenResize);

        return () => {
            window.removeEventListener('resize', handleScreenResize);
            window.removeEventListener('orientationchange', handleScreenResize);
            window.screen.orientation.removeEventListener('change', handleScreenResize);
        };

    }, [])

    const startGame = () => {
        setWaterCount(19);

        setGameIsStarted(true);
        setPlayCountDown(false);

        startTimerForCloudAnimation()
        setTimeout(() => {
            startTimerForDropletAnimation();
        }, 4000);
        setTimeout(() => {
            startTimerForWaterBar();
        }, 500);
    }

    function startTimerForWaterBar() {
        var delay = waterBarTimer;
        if (levelCount == 2)
            delay = 5000;
        else if (levelCount == 3)
            delay = 4000;
        else if (levelCount == 4)
            delay = 3000;

        setTimeout(removeWaterBar, delay);
    }

    function startTimerForCloudAnimation() {
        var cloudInterval = Math.random() * 60 + 60;
        console.log("cloud animation " + cloudInterval + " " + Date());
        setTimeout(animateCloud, cloudInterval * 100);
    }

    function startTimerForDropletAnimation() {
        var dropletAnimation = Math.random() * 20 + 15;
        console.log("droplet animation " + dropletAnimation + " " + Date());
        setRandomDropletNumber(-1); // reset it
        setTimeout(animateDroplet, dropletAnimation * 100);
    }

    const removeWaterBar = () => {
        setIsRemovingWater(true);
        setStartTogglingWater(true);

        setTimeout(() => {
            setIsRemovingWater(false);
        }, 300);
    }

    const handleScreenResize = () => {
        onScreenresize();
    }

    const onScreenresize = () => {
        resizeScreen();
        var referenceWidth = 1920.0;
        var referenceHeight = 1080.0;

        var windowWidth = context.width;
        var windowHeight = context.height;

        var _scaleWidth = windowWidth / referenceWidth;
        var _scaleHeight = windowHeight / referenceHeight;

        var _scale = Math.min(_scaleHeight, _scaleWidth);

        setScale(_scale);
    }

    const resizeScreen = () => {
        var ratio = 1920.0 / 1080.0;
        var windowHeight = Math.min(window.screen.height, window.innerHeight);
        var windowWidth = Math.min(window.screen.width, window.innerWidth);

        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (webkit && !iOSSafari) {
            windowHeight = context.innerHeight / window.devicePixelRatio;
            windowWidth = context.innerWidth / window.devicePixelRatio;
        }

        var webWidth = windowWidth;
        var webHeight = windowWidth / ratio;

        if (webHeight > windowHeight) {
            webHeight = windowHeight - 2;
            webWidth = webHeight * ratio;
        }

        context.width = Math.min(webWidth, 1920);
        context.height = Math.min(webHeight, 1080);
    }

    function randomIntFromInterval(min: number, max: number) { // min and max included 
        return Math.floor(Math.random() * max) + min;
    }


    const animateCloud = () => {
        if (showCloud === undefined || randomDropletNumber >= 4 || randomDropletNumber == -1 || gameIsStopped) {
            let rnd = randomIntFromInterval(1, 2);
            setRandomCloudNumber(rnd);
        } else {
            startTimerForCloudAnimation()
        }
    }

    const cloudAnimationEnd = () => {
        setTappedOnCloud(false);
        setShowCloud(undefined);
        setRandomCloudNumber(-1);

        startTimerForCloudAnimation()

    }

    const thunderCloudEnd = () => {
        setTappedOnCloud(false);
        setShowThunderCloud(false)
        startTimerForDropletAnimation();
    }

    const dropletCloudAnimationEnd = () => {
        setTappedOnCloud(false);
        setShowDropletCloud(false);
        startTimerForDropletAnimation();
    }

    const onCloudTap = () => {
        if (waterCount === 19)
            return;

        if (gameIsStopped)
            return;

        if (tappedOnCloud)
            return;

        console.log("cloud tap on droplet");
        setTappedOnCloud(true);
        onDropletTapped(Droplet.Cloud);

        setShowCloud(undefined);
        setShowThunderCloud(false)
        setShowDropletCloud(true);
    }

    const animateDroplet = () => {
        let rnd = randomIntFromInterval(1, 20);
        console.log("droplet number " + rnd + " " + Date());
        setRandomDropletNumber(rnd);
    }

    const pipeAnimationEnd = () => {
        setTappedOnPipe(false);
        setShouldAnimatePipe(false);
        startTimerForDropletAnimation();
    }

    const dropletPipeAnimationEnd = () => {
        setTappedOnPipe(false);
        setShowDropletPipe(false);
        startTimerForDropletAnimation();
    }

    const onPipeTap = () => {
        if (waterCount === 19)
            return;

        if (gameIsStopped)
            return;

        if (tappedOnPipe)
            return;

        console.log("pipe tap on droplet");
        setTappedOnPipe(true);
        onDropletTapped(Droplet.Pipe);
        setShowDropletPipe(true);

        setShouldAnimatePipe(false);
    }

    const wellWaterAnimationEnd = () => {
        setTappedOnWellWater(false);
        setShouldAnimateWellWater(false);
        startTimerForDropletAnimation();
    }

    const dropletWellWaterAnimationEnd = () => {
        setTappedOnWellWater(false);
        setShowDropletWell(false);
        startTimerForDropletAnimation();
    }

    const onWellWaterTap = () => {
        if (waterCount === 19)
            return;

        if (gameIsStopped)
            return;

        if (tappedOnWellWater)
            return;

        console.log("well tap on droplet");
        setTappedOnWellWater(true);
        onDropletTapped(Droplet.Well);

        setShouldAnimateWellWater(false);
        setShowDropletWell(true);
    }

    return (
        <div className='Game'>
            <AnimateLottie
                item={sun}
                removeItemAtEnd={true}
                loop={true}
                stop={false}
                hideButton={false}
                onEnd={() => { }}
                onTap={() => { }}
            />

            {showCloud && !showThunderCloud &&

                <AnimateLottie
                    item={showCloud}
                    removeItemAtEnd={true}
                    loop={false}
                    stop={false}
                    hideButton={showQuestion}
                    onEnd={cloudAnimationEnd}
                    onTap={() => { }}
                />

            }

            {showThunderCloud &&
                <AnimateLottie
                    item={cloud3}
                    removeItemAtEnd={true}
                    loop={false}
                    stop={showDropletCloud}
                    hideButton={showQuestion}
                    onEnd={thunderCloudEnd}
                    onTap={onCloudTap}
                />
            }

            {
                waterBar.map((bar, index) => {
                    if (index === 0 && isRemovingWater) {
                        return <img className="water-fadeout" key={bar.position.top} src={"images/water_bar.png"} alt='' style={{ width: bar.size.width, height: bar.size.height, top: bar.position.top, left: bar.position.left, position: 'absolute' }} />
                    } else if (index === 0 && isAddingWater) {
                        return <img className="water-fadein" key={bar.position.top} src={"images/water_bar.png"} alt='' style={{ width: bar.size.width, height: bar.size.height, top: bar.position.top, left: bar.position.left, position: 'absolute' }} />
                    }
                    else {
                        return <img key={bar.position.top} src={"images/water_bar.png"} alt='' style={{ width: bar.size.width, height: bar.size.height, top: bar.position.top, left: bar.position.left, position: 'absolute' }} />
                    }
                })
            }

            <AnimateItem
                item={well}
                loop={false}
                stop={false}
                hideButton={showQuestion || gameIsStopped}
                onEnd={() => { }}
            />


            <AnimateItem
                item={pipeStatic}
                loop={false}
                stop={false}
                hideButton={showQuestion || gameIsStopped}
                onEnd={() => { }}
            />


            {shouldAnimatePipe &&
                <AnimateLottie
                    item={pipe}
                    removeItemAtEnd={true}
                    loop={false}
                    stop={showDropletPipe}
                    hideButton={showQuestion || gameIsStopped}
                    onEnd={pipeAnimationEnd}
                    onTap={onPipeTap}
                />
            }

            {shouldAnimateWellWater &&
                <AnimateLottie
                    item={wellWater}
                    removeItemAtEnd={true}
                    loop={false}
                    stop={showDropletWell}
                    hideButton={showQuestion || gameIsStopped}
                    onEnd={wellWaterAnimationEnd}
                    onTap={onWellWaterTap}
                />
            }

            {showDropletPipe &&
                <AnimateLottie
                    item={dropletPipe}
                    removeItemAtEnd={true}
                    loop={false}
                    stop={!shouldAnimateWellWater}
                    hideButton={showQuestion || gameIsStopped}
                    onEnd={dropletPipeAnimationEnd}
                    onTap={() => { }}
                />
            }

            {showDropletWell &&
                <AnimateLottie
                    item={dropletWellWater}
                    removeItemAtEnd={true}
                    loop={false}
                    stop={!shouldAnimateWellWater}
                    hideButton={showQuestion || gameIsStopped}
                    onEnd={dropletWellWaterAnimationEnd}
                    onTap={() => { }}
                />
            }

            {showDropletCloud &&
                <>
                    <AnimateLottie
                        item={cloud3Buildout}
                        removeItemAtEnd={true}
                        loop={false}
                        stop={!shouldAnimateWellWater}
                        hideButton={showQuestion || gameIsStopped}
                        onEnd={dropletCloudAnimationEnd}
                        onTap={() => { }}
                    />

                    <AnimateLottie
                        item={dropletCloud}
                        removeItemAtEnd={true}
                        loop={false}
                        stop={!shouldAnimateWellWater}
                        hideButton={showQuestion || gameIsStopped}
                        onEnd={() => { }}
                        onTap={() => { }}
                    />
                </>
            }

            {playCountDown &&
                <div className="container" style={{
                    width: countDown.size.width, height: countDown.size.height, top: countDown.position.top, left: countDown.position.left
                }}>
                    <ReactPlayer
                        playing={true}
                        url={"video/countdown.mp4"}
                        width='100%'
                        height='100%'
                        loop={false}
                        muted={false}
                        config={{ file: { attributes: { playsInline: true, }, }, }}
                        onEnded={startGame}
                    />
                </div>
            }

            <CustomAudio
                item={Sun}
                loop={true}
                start={gameIsStarted} />

            <CustomAudio
                item={Pipe}
                loop={false}
                start={shouldAnimatePipe && gameIsStarted} />

            <CustomAudio
                item={WellWater}
                loop={false}
                start={shouldAnimateWellWater && gameIsStarted} />

            <CustomAudio
                item={Cloud3}
                loop={false}
                start={showThunderCloud && gameIsStarted} />

            <CustomAudio
                item={DropletCloud}
                loop={false}
                start={showDropletCloud && gameIsStarted} />

            <CustomAudio
                item={DropletPipe}
                loop={false}
                start={(showDropletPipe || showDropletWell) && gameIsStarted} />

        </div>
    )
}